<template>
  <div>


    <div class="text-center" v-if="load">
      <b-spinner variant="warning" label="Spinning"></b-spinner>
      <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="danger" label="Spinning"></b-spinner>
      <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="info" label="Spinning"></b-spinner>
      <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
      <!-- <b-spinner variant="primary" label="Spinning"></b-spinner>
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
      <b-spinner variant="success" label="Spinning"></b-spinner>
      <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
    </div>

    <div v-else>
      <b-form-checkbox v-model="exibirValores" switch size="lg">Exibir valores</b-form-checkbox>
      <b-button class="btnConfimar" variant="btn btn-success" @click="imprimir()" >Gerar PDF</b-button>
    </div>

  

    <div class="tabelaProdutos" v-show="false">
        <b-table id="tabelaProdutos" striped hover :items="dados.reservas" :fields="fields"></b-table>
    </div>



  </div>
</template>

<script>
import { acessoRestrito } from "@/global";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";
import dayjs from 'dayjs';

export default {
  name: "GerarPdf",
  props: {
    dados: {}
  },
  

  data() {
    return {

      load: false,
      dataHoje: null,
      dataIni: null,
      dataFim: null,
      valorTotalSinal: null,
      valorTotal: null,
      usuario: {},

      exibirValores: true,

      fields: [ ],

      fieldsComValores: [
        { key: "cod", label: "Cód." },
        { key: "nome", label: "Nome" },
        { key: "dataIn", label: "Entrada", formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Qtd." },
        { key: "quartos", label: "Quarto"},
        { key: "status", label: "Status" },

        { key: "valorSinal", label: "Valor sinal", formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }},

        { key: "valorColonia", label: "Valor colônia", formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }},

      ],

      fieldsSemValores: [
        { key: "cod", label: "Cód." },
        { key: "nome", label: "Nome" },
        { key: "dataIn", label: "Entrada", formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Qtd." },
        { key: "quartos", label: "Quarto"},
        { key: "status", label: "Status" },
      ],


    }
  },


  async created() {

    this.usuario = JSON.parse(localStorage.getItem('user'))

    this.dataIni = dayjs(this.dados.dataFiltro[0]).format('DD/MM/YYYY')
    this.dataFim = dayjs(this.dados.dataFiltro[1]).format('DD/MM/YYYY')

    this.dataHoje = dayjs().format('DD/MM/YYYY')
    
    this.valorTotalSinal = this.dados.reservas.reduce((total, item) => total + Number(item.valorSinal), 0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

    this.valorTotalColonia = this.dados.reservas.reduce((total, item) => total + Number(item.valorColonia), 0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

    this.valorTotal = this.dados.reservas.reduce((total, item) => total + Number(item.valorTotal), 0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })


    // await new Promise(r => setTimeout(r, 2000))
    // this.gerarPdf()
  },

  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    

    getImgUrl(nomeFile) {
        var images = require.context('../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },

    async imprimir() {

      this.load = true

      this.fields = this.exibirValores ? this.fieldsComValores : this.fieldsSemValores

      await this.gerarPdf()
      // await new Promise(r => setTimeout(r, 2000))
      
      this.$emit('EMIT_Fechar_modalAguarde')
    },


    async gerarPdf() {

      acessoRestrito(250)

      await new Promise(r => setTimeout(r, 2000))


      const colonia = this.$store.state.colonia
      const coloniaTexto = colonia == 'Chico Amaro'
      ? 'Colonia de Férias Chico Amaro - Itanhaém - SP'
      : 'Colonia de Férias Guarino Fernances - Presidênte Epitácio - SP'





      
      // var doc = jsPDF('p', 'mm', [210, 297]);
      var doc = jsPDF('l', 'mm', [210, 297]);
      // var doc = this.exibirValores ? jsPDF('l', 'mm', [210, 297]) : jsPDF('p', 'mm', [210, 297])

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 27, 27) 

      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text(coloniaTexto, width/2, 28, { align: 'center' })
      
      // doc.setFontSize(8).setFont(undefined, 'bold'); 
      // doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 34, { align: 'center' })

      // doc.setDrawColor(0, 0, 0);
      // doc.line(0, 36, 500, 36);


      doc.setFontSize(16);          
      doc.text(this.dados.titulo, width/2, 40, { align: 'center' })

      // doc.setFontSize(11).setFont(undefined, 'bold'); 
      // doc.text(coloniaTexto, width/2, 52, { align: 'center' })
      //------------------------------------------------------------------



      doc.autoTable({
        body: [

        this.exibirValores 
        ? [
            {
              content: `PERÍODO: ${this.dataIni} até ${this.dataFim}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `VALOR SINAL: ${this.valorTotalSinal}`,
              styles: { halign: "right", fillColor: [244, 247, 249] }
            },            
            {
              content: `VALOR COLÔNIA: ${this.valorTotalColonia}`,
              styles: { halign: "right", fillColor: [244, 247, 249] }
            },            
            {
              content: `VALOR TOTAL: ${this.valorTotal}`,
              styles: { halign: "right", fillColor: [244, 247, 249] }
            }            
          ]
        : [
            {
              content: `PERÍODO: ${this.dataIni} até ${this.dataFim}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            }           
          ],


        ],
        styles: {
          lineColor: [73, 138, 159],
          lineWidth: 0.2
        },
        theme: "plain",
        startY: 49
      });
                              



      // Tabela PRODUTOS
      //------------------------------------------------------------------
      doc.autoTable({
          html: '#tabelaProdutos',
          tableHegth: height + 20,
          margin: {top: 51, left: 14}, 
          // styles: { fillColor: "#43a047" },
          // headStyles: {fillColor : [124, 95, 240]}, 
          // alternateRowStyles: {fillColor : [231, 215, 252]}, 
          tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


          didDrawPage: function (data){
              data.settings.margin.top = 9;
              // data.settings.margin.left = 9;
          },
          theme: 'grid',
          // columnStyles: {
          //     0: {cellWidth: 15},
          //     1: {cellWidth: 25},                   
          //     2: {cellWidth: 30},                   
          //     3: {cellWidth: 30}                  
          // }
      }); 

      // var finalY = doc.lastAutoTable.finalY;
      // doc.setFontSize(10);
      // doc.text((width - 50), (finalY + 5), this.itemsConsolidacoes[1].valor);
      //------------------------------------------------------------------




      // Rodapé
      //------------------------------------------------------------------
      doc.setFontSize(9);          
      doc.text(9, (height - 10), 'Usuário: '+this.usuario.nome);

      doc.setFontSize(9);
      doc.text(120, (height - 10), 'Data: ' + dayjs().format('DD/MM/YYYY'));

      doc.setFontSize(9);
      doc.text(150, (height - 10), 'Hora: ' + dayjs().format('HH:mm'));

      var pageCount = doc.internal.getNumberOfPages();
      for(var i = 0; i < pageCount; i++) {
          doc.setPage(i); 
          let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
          doc.setFontSize(9);
          doc.text('Pág ' + pageCurrent + '/' + pageCount, 270, (height - 10));
      }
      //------------------------------------------------------------------


      let nomePdf = `Relatorio reservas • ${dayjs(this.dados.dataFiltro[0]).format('DD-MM-YYYY')} • `
      nomePdf += `${dayjs(this.dados.dataFiltro[1]).format('DD-MM-YYYY')}.pdf`

      doc.save(nomePdf)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}

/* .button-imprimir {
  margin-bottom: 25px;
  margin-right: 20px;
  height: 40px;
  width: 210px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
} */

.btnConfimar {
  margin-top: 55px;
  margin-left: 0px;
  height: 45px;
  width: 460px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  cursor: pointer; 
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>