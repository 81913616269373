<template>
  <div id="dashboard">


    <div>


      <b-row align-h="left" class="titulo">
        <h2>{{ titulo }}</h2>
      </b-row>

      <div v-if="tipoTela == 'check-in'">
        <b-card-group deck>

          <b-card text-variant="black" :header="dia.data" class="card text-center" 
            @click="setPeriodo(dia.dataAno)" 
            v-for="dia in saldosDias" :key="dia.data"
          >
            <div class="div-text">
              Reserva: {{ dia.totalReserva }}
              <br>
              Bar: {{ dia.totalBar }}
            </div>
          </b-card>

        </b-card-group>
      </div>

      <!-- <button type="button" @click="getReservasRelatorios">Refresh</button> -->


      <!-- <b-row align-h="center"> -->
      <div class="row text-left col-lg-12">

        <div class="col-lg-3 select-sede">
          <b-form-group label="Sede" label-for="bg-opacity">
            <b-input-group>
              <b-form-select
                v-model="sede"
                :options="sedes"
                @change="getReservas"
              ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-lg-3 select-sede">
          <b-form-group label="Empresa" label-for="bg-opacity">
            <b-input-group>
              <b-form-select
                v-model="empresa"
                :options="empresas"
                @change="getReservas"
              ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div>


        <div class="col-lg-3 calendario">
          <b-form-group label="Período" label-for="bg-opacity">
            <b-input-group>
              <date-picker  
                :key="index"
                id="input-periodo"
                class="data-picker"
                v-model="dataFiltro"
                lang="pt-br" 
                range
                style="width: 280px;"
                type="date"
                format="DD/MM/YYYY"
                @change="getReservas"> 
              </date-picker>
            </b-input-group>
          </b-form-group>
        </div>
        


        <div class="col-lg-3">    
  
          <!-- <button class="button-cadastrar">Gerar PDF</button> -->
          <b-button class="btnRight" variant="btn btn-success" @click="imprimir()" >Gerar PDF</b-button>
            
        </div>

        

      </div>
      <!-- </b-row> -->







      <div>
        <b-table
          id="my-table"
          striped
          hover
          class="text-left tabela-reservas"
          ref="selectableTable"
          responsive="true"
          :items="reservas"
          :fields="fieldsReservas"
          :busy="load"
          small
          outlined
        >

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>

        </b-table>
      </div>

      

    </div> 


    <!-- MODAL AGUARDE -->
    <div>
      <b-modal ref="modal-aguarde" hide-footer :title="titulo" centered size="mx">

        <RelatorioReservasPDF :dados="{ titulo, dataFiltro, reservas: reservas}"
          @EMIT_Fechar_modalAguarde="EMIT_Fechar_modalAguarde()"
        ></RelatorioReservasPDF>         
        
      </b-modal>
    </div>
    <!-- MODAL AGUARDE FIM -->


  </div>
</template>



<script>
import api from "../../services/api";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import dayjs from 'dayjs'
import RelatorioReservasPDF from './RelatorioReservasPDF'

export default {
  components: { 
    DatePicker,
    RelatorioReservasPDF
   },
   props: {
    tipoTela: null
   },

  data() {
    return {


      index: 1,
      visivel: false, 
      load: false,  
      titulo: null,

      sede: 'TODAS',
      sedes: [],

      empresa: 'TODAS',
      empresas: [],

      dataFiltro: [],   
      saldosDias: [],

      exibirValores: true,

      reservas: [], 
      fieldsReservas: [
        // { key: "Tipo", label: "", sortable: true },
        { key: "cod", label: "Cód.", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Hóspedes", sortable: true },
        { key: "quartos", label: "Quarto", sortable: true },
        { key: "status", label: "Status", sortable: true },
        
        { key: "valorSinal", label: "Valor sinal", sortable: true, formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }},
        { key: "valorColonia", label: "Valor colônia", sortable: true, formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }},
        { key: "valorTotal", label: "Valor total", sortable: true, formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }},
        { key: "valorBar", label: "Valor bar", sortable: true, formatter: value => { 
          return  value 
                  ? value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })
                  : 'R$ 0,00';
        }}
        
      ],
        
  
    }
  },

  created() {

    // const colonias = this.$store.state.colonias 
    // this.dataFiltro[0] = new Date(dayjs())
    // this.dataFiltro[1] = new Date(dayjs())

    // console.log('tela', this.tipoTela)
    this.dataFiltro[0] = new Date(dayjs())
    this.dataFiltro[1] = new Date(dayjs())

    this.titulo = this.tipoTela == 'geral' ? 'Relatório geral' : 'Relatório - por data de check-in'

    this.colonia = this.$store.state.colonia
    this.getSaldosDias()
    this.getListaSedesEmpresas()
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    getListaSedesEmpresas() {

      api.get(`reservas-relatorios/lista-sede-empresas`)
      .then((res) => {

        // console.log(res.data)
        this.sedes = res.data.sedes
        this.empresas = res.data.empresas
      })
      .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })
    },

    getSaldosDias() {

      if (this.tipoTela == 'geral')
      return

      api.get(`reservas-relatorios/saldo-dias/${this.colonia}`)
      .then((res) => {

        // console.log(res.data)
        this.saldosDias = res.data
      })
      .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })
    },

    getReservas() {

      const dataIn = dayjs(this.dataFiltro[0]).format('YYYY-MM-DD')  + ' 00:00'
      const dataOut = dayjs(this.dataFiltro[1]).format('YYYY-MM-DD') + ' 23:59'


      api.get(`reservas-relatorios/reservas/${this.colonia}/${dataIn}/${dataOut}/${this.tipoTela}/${this.sede}/${this.empresa}`)
      .then((res) => {

        // console.log(res.data)
        this.reservas = res.data
      })
      .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })
    },

    setPeriodo(dataAno) {

      this.dataFiltro[0] = new Date(dayjs(dataAno))
      this.dataFiltro[1] = new Date(dayjs(dataAno))

      this.index++

      this.getReservas()
    },

    async imprimir() {

      // if (this.dataFiltro[0] == null || this.dataFiltro[1] == null) {
      //   this.msgNotification('Informe o período', 'info', 5000)
      //   return
      // }

      // if (this.items.length == 0) {
      //   this.msgNotification('Não existe fluxo de produtos neste período.', 'info', 5000)
      //   return
      // }

      this.$refs["modal-aguarde"].show()
      // await new Promise(r => setTimeout(r, 2000))
      // this.$refs["modal-aguarde"].hide()
    },

    EMIT_Fechar_modalAguarde() {
      
      this.$refs["modal-aguarde"].hide()
    },




  }



};
</script>



<style scoped>
#dashboard {
  height: 91vh;
  width: 90vw;
  /* background-color: rgb(240, 240, 240); */
  padding: 35px;
}

.titulo {
  margin-top: 10px;
  margin-left: 1px;
  margin-bottom: 20px;
  color: rgb(83, 83, 86);
  /* font-weight: bold; */
}

.card-body {
  padding: 0;
}

.card {
  font-size: 18px;
  font-weight: bold;
  /* color: #8E22D5;
  color: rgb(83, 83, 86);
  color: #27A645; */
  color: #FB8600;
  cursor: pointer;
  
}

.div-text {
  /* color: #8E22D5;
  color: #27A645; */
  color: #FB8600;
}

img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-sede {
  margin-top: 32px;
  width: 50px;
}

.calendario {
    margin-top: 32px;
    margin-left: 0px;
}


.btnRight {
  margin-top: 64px;
  margin-left: 0px;
  height: 37px;
  width: 300px;
  /* border: 0;
  border-radius: 50px; */
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer; 
}

.data-picker {
  top: 0px;
}

.tabela-reservas {
  min-height: 420px;
  margin-top: 20px;
}



</style>